import React from 'react'

import Article from 'components/article'

import Image1 from '../img/articles/covid-vaccination-portal/1.jpg'
import Image2 from '../img/articles/covid-vaccination-portal/2.jpg'
import Image3 from '../img/articles/covid-vaccination-portal/3.jpg'
import Image4 from '../img/articles/covid-vaccination-portal/4.jpg'
import Image5 from '../img/articles/covid-vaccination-portal/5.jpg'

import ThreeImageWrapper from 'components/threeImageWrapper'
import TwoImageWrapper from 'components/twoImageWrapper'
import ImgPortrait from 'components/imgPortrait'
import ImgLandscape from 'components/imgLandscape'
import Seo from 'components/seo'

const CovidVaccinationPortal = () => {
  return (
    <Article
      title="Vaccination portal"
      description="COVID Vaccination planner for Schleswig-Holstein"
      from="2020"
      to="2021"
      roles={['Product Designer', 'Web Developer']}
      text={
        <>
          <p>
            The COVID Vaccination Shift Planner was used to distribute doctors
            to vaccination centers and vaccination shifts in Schleswig-Holstein.
          </p>
          <p>
            The application was designed and developed within a few weeks for
            the KVSH (Kassenärztliche Vereinigung Schleswig-Holstein) and later
            extended to include additional features such as shift exchange,
            vaccine delivery management, and vaccine shortage notifications.
          </p>
        </>
      }
      more={
        <>
          <Seo title="Vaccination Portal" />
          <ThreeImageWrapper>
            <ImgPortrait img={Image2} aspectRatio={10 / 16} />
            <ImgPortrait img={Image1} aspectRatio={10 / 16} />
            <ImgPortrait img={Image3} aspectRatio={0.74} />
          </ThreeImageWrapper>
          <TwoImageWrapper>
            <ImgLandscape img={Image4} aspectRatio={1} />
            <ImgLandscape img={Image5} aspectRatio={1} />
          </TwoImageWrapper>
        </>
      }
    />
  )
}

export default CovidVaccinationPortal
